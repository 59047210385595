.admin_patient{
  padding: 2.2rem;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }

  &_title{
    font-size: 30px;
    color: #414141;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
  }
  &_search_button{
    margin-top: 10px;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &_skeleton{
    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
    &_avatar{
      @media only screen and (max-width: 768px) {
        text-align: center;
        margin: 15px 0;
      }

    }
  }
}
