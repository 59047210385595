.physician__patient{
  padding: 2.2rem;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
  h5{
    font-size: 16px;
    color: $colorThird;
    padding-bottom: .625rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  &__info__title{
    font-size: 20px;
    padding-bottom: 20px;
    font-weight: 700;
    color: $colorThird;
  }

  p{
    font-size: 16px;
    line-height: 1.25rem;
    color: $colorThird;
  }

  .skeleton__physician__patient{
    &__text__align{
      margin-top: 35px;
    }
  }


}
