.admin_medicines_new_brand{
  padding: 2.2rem;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
  &_title{
    font-size: 30px;
    color: $colorThird;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
  }

  .button__primary{
    &.btn_delete{
      background-color: $colorDelete;
      border: 1px solid #fff;
      width: 7rem;
      font-size: 1rem;
      text-transform: none;
    }
  }

}
