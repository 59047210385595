.physician__prescriptions__prescription{
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: none;
  box-shadow: 0 2px 5px 2px rgba(222, 222, 222, 0.4);
  margin-bottom: 50px;

  &__header-container{
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0,0,0,0.125);
  }
  &__body{
    min-height: 250px;
    padding: 1.25rem;
    display: flex;
  }
  &__footer{
    border-top: 1px solid rgba(0, 0, 0, 0.125);
      &__steps{
        padding: 10px;
        @media only screen and (max-width: 768px) {
          flex-direction: column;
        }
        .steps {
          padding:10px;
          }
        @media only screen and (max-width: 768px) {
          span{
            width: 90%;
            text-align: center;
            font-size: 16px;
            margin-bottom: 10px!important;
          }

        }
        .active-badges{
          padding: 8px 16px;
          background: $colorPrimary;
          border-radius: 16px;
          color:#fff;
          @media only screen and (max-width: 768px) {
            width: 90%;
            color: #fff;
            text-align: center;
            font-size: 16px;
            margin-bottom: 10px!important;
          }
        }
      }
  }
  .progress-bar {
    background-color: $colorPrimary;
  }


}
