.buy__cbd{
  color: $colorThird;

  .button__primary{
    width: 12.5rem;
    font-size: 1.1rem;
    text-transform: none;
    font-weight: normal;
  }
  &__title{
    font-size: 2rem;
    text-align: center;
  }
  &__subtitle{
    font-size: 1.3rem;
    text-align: justify;
    color: #6F6F6F;
    line-height: 2.2rem;
  }
  &__banner{
    &__title{
      color: #fff;
      @media only screen and (max-width: 767px) {
        font-size: 1.8rem;
      }
    }
    &__content{
      color: #fff;
      line-height: 2.2rem;
      text-align: justify;
      @media only screen and (max-width: 767px) {
        font-size: 1.3rem;
      }

    }
    &.notebook{
      padding: 5.7rem 0 6.5rem 0;
      background: linear-gradient(rgba(28, 41, 96, 0.5), rgba(28, 41, 96, 0.5))
      no-repeat,
      url(../../images/comprar-cbd1.jpg) 100% 50% no-repeat;
      background-size: cover;
      @media only screen and (max-width: 767px) {
        padding: 8rem 0 9rem 0;
        background: linear-gradient(
            rgba(28, 41, 96, 0.5),
            rgba(28, 41, 96, 0.5)
        )
        no-repeat,
        url(../../images/comprar-cbd1.jpg) no-repeat;
        background-size: cover;
      }

    }
    &.medicine {
      padding: 9rem 0 6.5rem 0;
      background: linear-gradient(
          rgba(113, 170, 116, 0.3),
          rgba(113, 170, 116, 0.3)
      )
      no-repeat,
      url(../../images/comprar-cbd2.jpg) 100% 30% no-repeat;
      background-size: cover;
      @media only screen and (max-width: 767px) {
        padding: 9rem 0 12rem 0;
        background: linear-gradient(
            rgba(113, 170, 116, 0.3),
            rgba(113, 170, 116, 0.3)
        )
        no-repeat,
        url(../../images/comprar-cbd2.jpg) 100% 30% no-repeat;
        background-size: cover;
      }
    }

    &.woman{
      padding: 5rem 0 9rem 0;
      background: linear-gradient(rgba(28, 41, 96, 0.5), rgba(28, 41, 96, 0.5))
      no-repeat,
      url(../../images/sobrenos.jpg) 100% 22% no-repeat;
      background-size: cover;
      @media only screen and (max-width: 767px) {
        padding: 10rem 0 11rem 0;
        background: linear-gradient(
            rgba(28, 41, 96, 0.5),
            rgba(28, 41, 96, 0.5)
        )
        no-repeat,
        url(../../images/sobrenos.jpg) 100% 22% no-repeat;
        background-size: cover;
      }
    }
    &.anvisa{
      padding: 3rem 0 9rem 0;
        .buy__cbd__banner__title{
          color: $colorThird;
          @media only screen and (max-width: 767px) {
            font-size: 1.8rem;
          }
        }
      .buy__cbd__banner__content{
        color: $colorThird;
        line-height: 1.9rem;
      }
    }
  }

}
