.patient__physician__description{
  padding: 3rem;
    &__title{
      font-size: 16px;
      color: $colorThird;
      padding-bottom: 0.625rem;
      text-transform: uppercase;
      font-weight: 600;
        @media only screen and (max-width: 575px) {
          &.about{
            margin-top: 1.875rem;
          }
        }
    }

  &__recommend{
    box-shadow: 0 2px 3px rgba(214, 214, 214, 0.5);
    padding: 15px;
    border-bottom: 2px solid #fff;

    &__title{
       font-size: 16px;
       height: 45px;
       color: $colorThird;
       padding: 5px 0;
       font-weight: 700;
       line-height: 20px;
       display: block;
       display: -webkit-box;
       margin: 10px auto;
       -webkit-line-clamp: 2;
       -webkit-box-orient: vertical;
       overflow: hidden;
       text-overflow: ellipsis;

     }
    &__description{
      font-size: 16px;
      line-height: 20px;
      display: block;
      display: -webkit-box;
      margin: 10px auto;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img{
        width: 100%;
      height: 180px;
        max-height: 230px;

    }
    &:hover{
      border-bottom: 2px solid $colorPrimary;
    }
  }
  &__text{
    font-size: 16px;
    line-height: 1.25rem;
    color:$colorThird;
    @media only screen and (max-width: 768px) {
          line-height: 20px;
    }
  }


  &__badge__buttons{
    background-color: $colorPrimary;
    color: #fff;
    border-radius: 16px;
    padding: 3px 1.563rem;
    margin-top: 10px;
    margin-right: 0.625rem;
    text-transform: capitalize;
    font-size: 16px;
    display: inline-block;

  }
  .profile__card{
    @media only screen and (max-width: 575px) {
      flex-direction: column;
      text-align: center;
    }

  }
  .profile__card__description{
    @media only screen and (max-width: 575px) {
        margin-top: 0.938rem;
    }
  }
  &__skeleton{
      &__card{
        @media only screen and (max-width: 575px) {
          flex-direction: column;
          text-align: center;
        }
        &__full{
          @media only screen and (max-width: 575px) {
            width: 70%!important;
          }
        }
        .rounded-circle{
          @media only screen and (max-width: 575px) {
           margin-bottom:  0.938rem;
          }
        }


      }

  }
}
