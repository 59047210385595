.physician__patients{
  padding: 2.2rem;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
    &__title{
      font-size: 30px;
      color: $colorThird;
      padding-bottom: 10px;
      border-bottom: 1px solid #efefef;
      margin-bottom: 20px;
    }
  &__info{
    font-size: 20px;
    padding-bottom: 20px;
    font-weight: 700;
    color:  $colorThird;
  }
  &__text{
    font-size: 16px;
  }

  .skeleton__physician__patients{
    width: 100%;
    &__text__align{
      margin-top: 2rem;
      width: 100%;
    }
  }
}
