.my-agenda{
  .fc-event-title{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 15px;
  }
  &__configure-shifts{
    padding-right:  32px;
    padding-left: 32px;
    display: flex;
    align-items: center;
      &:hover{
        opacity: 0.7;
      }
     i{
        margin-right: 10px;
        font-size: 24px;
      }
  }
}