.custom__checkbox{

  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 1.563rem;
    margin-bottom:0.75rem;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkbox-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 0.938rem;
    width:0.938rem;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #6C757D ;
  }

  .checkbox-container input:checked ~ .checkbox-checkmark {
    border: 1px solid $colorPrimary;
  }

  .checkbox-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkbox-container input:checked ~ .checkbox-checkmark:after {
    display: block;
  }

  .checkbox-container .checkbox-checkmark:after {
    left: 0.25rem;
    top: 0;
    width: 0.313rem;
    height: 0.625rem;
    border: solid $colorPrimary;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

}
