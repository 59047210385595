/*Font Size*/
.font-rem-1-0 { font-size: 1.0rem !important; }
.font-rem-1-1 { font-size: 1.1rem !important; }
.font-rem-1-2 { font-size: 1.2rem !important; }
.font-rem-1-3 { font-size: 1.3rem !important; }
.font-rem-1-4 { font-size: 1.4rem !important; }
.font-rem-1-5 { font-size: 1.5rem !important; }
.font-rem-1-6 { font-size: 1.6rem !important; }
.font-rem-1-7 { font-size: 1.7rem !important; }
.font-rem-1-8 { font-size: 1.8rem !important; }
.font-rem-1-9 { font-size: 1.9rem !important; }
.font-rem-2-0 { font-size: 2.0rem !important; }

.font-size-9 { font-size: 9px !important; }

.font-size-8 { font-size: 8px !important; }

.font-size-9 { font-size: 9px !important; }

.font-size-10 { font-size: 10px !important; }

.font-size-11 { font-size: 11px !important; }

.font-size-12 { font-size: 12px !important; }

.font-size-13 { font-size: 13px !important; }

.font-size-14 { font-size: 14px !important; }

.font-size-15 { font-size: 15px !important; }

.font-size-16 { font-size: 16px !important; }

.font-size-17 { font-size: 17px !important; }

.font-size-18 { font-size: 18px !important; }

.font-size-19 { font-size: 19px !important; }

.font-size-20 { font-size: 20px !important; }

.font-size-21 { font-size: 21px !important; }

.font-size-22 { font-size: 22px !important; }

.font-size-23 { font-size: 23px !important; }

.font-size-24 { font-size: 24px !important; }

.font-size-25 { font-size: 25px !important; }

.font-size-26 { font-size: 26px !important; }

.font-size-27 { font-size: 27px !important; }

.font-size-28 { font-size: 28px !important; }

.font-size-29 { font-size: 29px !important; }

.font-size-30 { font-size: 30px !important; }

.font-size-35 { font-size: 35px !important; }

.font-size-40 { font-size: 40px !important; }

.font-size-45 { font-size: 45px !important; }

.font-size-50 { font-size: 50px !important; }

.font-size-55 { font-size: 55px !important; }

.font-size-60 { font-size: 60px !important; }

.font-size-65 { font-size: 65px !important; }

.font-size-70 { font-size: 70px !important; }

.font-size-75 { font-size: 75px !important; }

.font-size-80 { font-size: 80px !important; }

.font-size-85 { font-size: 85px !important; }

.font-size-90 { font-size: 90px !important; }

.font-size-95 { font-size: 95px !important; }

.font-size-100 { font-size: 100px !important; }

.font-size-105 { font-size: 105px !important; }

.font-size-110 { font-size: 110px !important; }

.font-size-115 { font-size: 115px !important; }

.font-size-120 { font-size: 120px !important; }

.font-size-125 { font-size: 125px !important; }

.font-size-130 { font-size: 130px !important; }

.font-size-135 { font-size: 135px !important; }

.font-size-140 { font-size: 140px !important; }

.font-size-145 { font-size: 145px !important; }

.font-size-150 { font-size: 150px !important; }

.font-size-155 { font-size: 155px !important; }

.font-size-160 { font-size: 160px !important; }

.font-size-165 { font-size: 165px !important; }

.font-size-170 { font-size: 170px !important; }

.font-size-175 { font-size: 175px !important; }

.font-size-180 { font-size: 180px !important; }

.font-size-185 { font-size: 185px !important; }

.font-size-190 { font-size: 190px !important; }

.font-size-195 { font-size: 195px !important; }

.font-size-200 { font-size: 200px !important; }
