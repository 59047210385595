.brand {
  &--text {
    border-bottom: 5px solid #72c7b6 !important;
  }
  &--list_card {
    overflow: auto;
    height: 550px;
  }
  &--list_videos {
    overflow: auto;
    height: 320px;
  }
  &--card_item {
    min-height: 366px;
  }
  &__cover {
    filter: opacity(0.5);
    width: 100%;
    &--icon {
      position: absolute;
      left: 10%;
      top: 30%;
    }
  }
  &__medicine {
    cursor: pointer;
  }
}

.brand--list_card::-webkit-scrollbar {
  width: 0.6em;
}

.brand--list_card::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.brand--list_card::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  outline: 1px solid slategrey;
}

.brand--list_videos::-webkit-scrollbar {
  width: 0.6em;
}

.brand--list_videos::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.brand--list_videos::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  outline: 1px solid slategrey;
}
