.physician__profile__show{
  padding: 2.2rem;
  @media only screen and (max-width: 575px) {
    padding: 0;
  }
  h4{
    font-size: 16px;
    color: $colorThird;
    padding-bottom: .625rem;
    text-transform: uppercase;
    font-weight: 600;
  }
  p.data{
    font-size: 16px;
    line-height: 1.25rem;
    color: $colorThird;
  }

  &__title{
    text-transform: capitalize;
    font-size: 30px;
    color: $colorThird;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
    @media only screen and (max-width: 575px) {
      font-size: 25px;
    }
  }

  &__buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 575px) {
      margin-top: 10px;
    }
  }


  &__btn{
    width: 50%;
    @media only screen and (max-width: 575px) {
      max-width: 230px;
    }
  }

  .profile__card{
    @media only screen and (max-width: 575px) {
      flex-direction: column;
      .profile__card__description{
        margin-top: 10px;
      }
    }
  }

  .profile__card__description__crm{
    @media only screen and (max-width: 575px) {
      text-align: center;
    }
  }

}
