.orders{
  padding: 2.2rem;
  @media only screen and (max-width: 575px) {
    padding: 20px;
  }
  &__title{
    font-size: 30px;
    font-weight: normal;
    color: $colorThird;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
    margin-left: -10px;
  }

  &__skeleton{
    &__header{
      @media only screen and (max-width: 575px) {
       text-align: center;
      }
      &__img{
        @media only screen and (max-width: 575px) {
          margin-bottom: 15px;
        }
      }
    }
  }
}
