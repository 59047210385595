.new--brand {
  &--btn {
    color: #ffffff;
    background-color: #1c2960;
    transition: all 0.2s;

    &:hover {
      color: #ffffff;
      background-color: #0e1430;
      transition: all 0.2s;
    }
  }
}
