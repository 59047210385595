.div-modal-payment{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: whitesmoke;
  border-radius: 6px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;

  &__div-img{
    width: 10rem;
    height: 9rem;
    margin-right: 2rem;

    &__img{
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

  }

  &__textos{
    width: 80%;

    &__p{
      margin-bottom: .8rem;
      font-size: 1.1em;
    }
    &__h1{
      font-size: 1.6em;
      margin-bottom: 1rem;
      line-height: 32px;
      text-align: center;
    }
    &__span-green{
      font-weight: 500;
    }

  }

}

.div-pay-modal {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0rem;
  padding-top: 1rem;

  &__div-btns {
    width: 36%;

    &__btn {
      display: flex;
      align-items: center;
      padding: 1rem;
      cursor: pointer;
      border-top: 1px solid whitesmoke;
      padding-top: 1.4rem;
      padding-bottom: 1.4rem;
      font-weight: 500;
      font-size: 1em;
      color: #2e2e2e
    }
    &__icon {
      margin-right: .5rem;
      font-size: 1.3em;
    }
  }

  &__div-card-qrcod {
    width: 64%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: whitesmoke;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    flex-direction: column;

    &__p-descricao{
      margin-top: 2rem;
      text-align: center;
      line-height: 23px;
      &__link{
        color: #118bb2;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    &__div-img-qrcode {
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 55%;
      margin: 1.5rem;
      padding: 1rem;
      border-radius: 6px;

      &__img-qrcode {
        width: 100%;
        height: auto;
      }

    }
    &__img-pix{
      width: 13%;
      margin: 1.5rem;
    }
    &__div-link{
      background-color: white;
      padding: 1rem;
      padding-top: .5rem;
      padding-bottom: .5rem;
      border-radius: 8px;
      text-align: center;
    }
    &__inputCopiar{
      width: 100%;
      height: 45px;
    }
    &__inputCopiar input{
      border-color: #FFFFFF !important;
    }
    &__btnCopiar{
      background-color: whitesmoke;
      border: 1px solid #ececec;
      margin-right: .2rem;
      padding: .5rem;
      border-radius: 4px;
      color: #333333;
      width: 90px;
    }
    &__div-btnCopiar{
      position: absolute;
    }
    &__div-input-btnCopiar{
      width: 55%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

}

.color-powered{
  color: #8f8f8f
}

.ativo{
  color: #6b6b6b;
  background-color: whitesmoke;
  cursor: default;
}
.card-order .collapse.credit__card{
  flex-direction: column;
}
.card-order .collapse.credit__card .payment__button__clear{
  display: none;
}

.card-order .collapse.credit__card .payment__button{
  width: 100%;
}

//Responsivo

@media only screen and (max-width: 770px) {
  .div-modal-payment{
    padding-left: 1rem;
    padding-right: 1rem;

    &__div-img{
      width: 6rem;
      height: 5rem;
    }

    &__textos{
      width: 90%;

      &__p{
        font-size: 1em;
      }
      &__h1{
        font-size: 1.1em;
        line-height: 25px;
      }

    }

  }

  .div-pay-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__div-btns {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__div-card-qrcod {
      width: 100%;
      min-height: 465px;
      height: inherit;
      max-height: 100%;
      border-top-right-radius: 0px;
      flex-direction: column;

      &__p-descricao{
        margin-top: 4rem;
      }
      &__div-img-qrcode {
        width: 75%;
      }
      &__img-pix{
        width: 20%;
        margin-bottom: 3rem;
      }
      &__div-link{
        background-color: white;
        padding: 1rem;
        padding-top: .5rem;
        padding-bottom: .5rem;
        border-radius: 8px;
        text-align: center;
      }

    }

  }
  .cardbtn{
    width: 62%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .pixbtn{
    width: 38%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

}
