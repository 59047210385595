.admin_articles{
  padding: 2.2rem;
  &_item {
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    margin: 0.5rem 0;
    p{
      width: 90%;
      padding-right: 10px;
    }
  }
  &_title{
    font-size: 30px;
    color: #414141;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
    @media only screen and (max-width: 575px) {
      font-size: 28px;
    }
  }
  .btn_save_new_article{
    width: 7rem;
    font-size: 1rem;
    text-transform: none;
  }
  .btn_delete{
    background-color: #eb4d4b;
    border: 1px solid #fff;
    width: 7rem;
    font-size: 1rem;
    text-transform: none;
    &:hover{
      opacity: 0.7;
      background-color:#eb4d4b ;
    }
  }
  .btn_new_article{
    text-transform: none;
    font-size: 1rem;
  }
}
