.physician__prescriptions{
  padding:2.2rem;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
  &__title{
    font-size: 30px;
    color: #414141;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
    margin-bottom: 20px;

  }
}
