.admin_physician{
  padding: 2.2rem;
  h4{
    font-size: 16px;
    color: #414141;
    padding-bottom: .625rem;
    text-transform: uppercase;
    font-weight: 600;
  }
  p{
    font-size: 16px;
    line-height: 1.25rem;
    color: #414141;
  }

  .button__primary{
    text-transform: none;
    &.btn_delete{
      background-color: $colorDelete;
      border: none;
    }
  }
  &_comment{
    border: 1px solid #ced4da;
    margin-bottom: 25px;
    padding: 20px;
    border-radius: 8px;
  }
  &_badges{
    background-color: #72c7b6;
    color: #fff;
    border-radius: 16px;
    padding: 3px 1.563rem;
    margin-top: 10px;
    margin-right: .625rem;
    text-transform: capitalize;
    font-size: 16px;
    display: inline-block;
  }
}
