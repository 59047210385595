.admin_campaign {
  padding: 2.2rem;
  &_title{
    font-size: 30px;
    color: $colorThird;
    @media only screen and (max-width: 575px) {
      font-size: 28px;
    }
  }
  p{
    font-size: 16px;
    line-height: 1.25rem;
    color: #414141;
  }
  &-card {
    cursor: pointer;
    min-height: 165px;
  }
}
