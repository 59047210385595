.admin_order{
  padding: 2.2rem;
  &_card{
    box-shadow: 0 1px 3px 2px rgba(214, 214, 214, 0.5);
    border-radius: 8px;
    min-height: 170px;
    padding: 10px;
    margin: 20px 0;
    b{
      margin: 10px 0 5px 0;
      display: inline-block;
      font-size: 15px;
    }
    a{
      font-size: 15px;
      &:hover{
        color: $colorPrimary;
      }
    }
    p{
      font-size: 15px;
    }
  }

  &_docs{
    display: flex;
    &_file{
      display: flex;
      flex-direction: column;
      align-items: center;
      img{
        width: 50px;
        height: 50px;
      }
    }
  }
  &_products{
    box-shadow: 0 1px 3px 2px rgba(214, 214, 214, 0.5);
    border-radius: 8px;
    padding: 15px;
    &_img{
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 200px;
        height: 200px;
      }
    }
    &_name{
      color: $colorThird;
      font-size: 25px;
      &:hover{
        color: $colorPrimary;
      }
    }
    &_desc{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    p{
      margin-bottom: 10px;
    }
    table{
      input{
        display: block;
        width: 100%;
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
      }
    }
  }
  .button__primary{
    text-transform: none;
    &.btn-delete{
      background-color: #eb4d4b;
      border: none;
    }
  }
  .button__secondary{
    text-transform: none;
  }
}
