.custom__mult__select{

  .multiselect-container{
    margin: 10px 0;

  }
  .search-wrapper{
    padding: 0 5px;
    font-size: 16px;

    .chip{
      font-size: 16px;
      background: $colorPrimary;
      margin-top: 5px;
    }
    input{
      height: 44px;
    }
  }

}
