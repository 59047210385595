.article{
  &__title{
      font-size: 20px;
      color: $colorThird;
      margin-bottom: 8px;
    &:hover{
      color: $colorPrimary;
    }
  }
  &__autors{
    font-size: 16px;
    color: #858997;
    margin-bottom: 5px;
  }

  &__abstract{
    margin-top: 15px;
    p{
      color: $colorThird;
      line-height: 25px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 18px;
      @media only screen and (max-width: 678px) {
        font-size: 16px;
      }
    }
  }
  &__link{
    color: $colorPrimary;
    font-weight: 500;
    font-size: 16px;
    &:hover{
      color: $colorThird;
    }
  }
  &__info{
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
}
