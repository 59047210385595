.admin_patient_edit{
  padding: 2.2rem;
  &_title{
    font-size: 30px;
    color: #414141;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
  }
}
