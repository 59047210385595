.physician__profile__edit{
  padding: 2.2rem;
  &__title{
    font-size: 30px;
    color: $colorThird;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
    @media only screen and (max-width: 575px) {
      font-size: 28px;
    }
  }
  &__info__title{
    font-size: 20px;
    padding-bottom: 20px;
    font-weight: 700;
    color: $colorThird;
    @media only screen and (max-width: 575px) {
      font-size: 20px;
    }
  }
}
