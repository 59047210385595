.admin_prescription{
  padding: 2.2rem;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
  img{
    width: 70px;
    height: 70px;
  }
  &_title{
    font-size: 30px;
    color:$colorThird;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
  }
  &_sub_title{
    font-size: 20px;
    font-weight: 700;
    color:$colorThird;
  }
  &_order_badge{
    font-size: 16px;
    padding: .188rem .938rem;
    border-radius: 16px;
    width: 120px;
    border:1px solid #c3c3c3;
    color: $colorThird;
      &:hover{
        color: $colorPrimary;
      }

  }
  &_card{
    box-shadow: 0 1px 3px 2px rgba(214, 214, 214, 0.5);
    border-radius: 8px;
    padding: 20px;
    min-height: 180px;
    &_title{
      font-size: 18px;
      text-transform: uppercase;
      color:$colorThird;
      font-weight: 700;
    }
    a{
      color: $colorThird;
      text-decoration: underline;
      font-size: 16px;
      &:hover{
       color: $colorPrimary;
      }
    }
    &_file{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
      p{
        margin-left: 10px;
        padding-top: 10px;
      }
    }
  }
  &_products{
    box-shadow: 0 1px 3px 2px rgba(214, 214, 214, 0.5);
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 30px;
    margin-top: 10px;

    &_name{
      a{
        color: $colorThird;
        font-size: 20px;
        margin: 15px 0;
        display: inline-block;
        &:hover{
          color: $colorPrimary;

        }
      }
    }
  }
  .button__primary{
    text-transform: none;
    &.btn_delete{
      background-color: #eb4d4b;
      border: none;
      @media only screen and (max-width: 768px) {
        margin-top: 10px;
      }
    }
  }

  &_uploads{
    .thumbs{
      width: 70px;
      height: 70px;
      padding: 0;
      .inner{
        img{
          width: 70px;
          height: 70px;
        }
      }

    }
    .dropzone{
      margin-top: 10px;
      button{
        width: 120px;
        font-size: 16px;
      }
    }
  }

  .valueInput{
    input{
      display: block;
      width: 100%;
      height: calc(1.5em + .75rem + 2px);
      padding: .375rem .75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
  }


}
