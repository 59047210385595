.orders__order{

  &__product__info{
    width: 100%;
    padding:0 15px;

    &__name{
      h4{
        font-size: 16px;
        font-weight: 700;
        color: $colorThird;
        padding-bottom: 10px ;
      }
      p{
        font-size: 16px;
        padding-bottom: 5px;
      }
    }

    &__quantity{
      margin-top: 30px;
      font-size: 16px;
    }

    &__shipping{
      font-size: 16px;
        h6{
          font-weight: 700;
          color: $colorThird;
          font-size: 16px;
        }
    }
  }

  &__header{
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 15px;
    h2{
      font-size: 20px;
      color: $colorThird;
      font-weight: 700;
      padding-bottom: 5px;

      @media only screen and (max-width: 575px) {
          font-size: 17px;
      }
    }
  }

  &__product__image{
    &__thumb{
      border: 1px solid #e3e3e3;
      width: 100px!important;
      height: 100px!important;

      img{
        width: 100px;
        height: 100px;
      }
    }
    @media only screen and (max-width: 575px) {
      display: flex;
      justify-content: center;
      padding-bottom: 30px;
    }
  }

  &__box{
    box-shadow: -1px 0px 13px 0px rgba(214,214,214,0.5);
    padding: 15px 0 0 0;
    border-radius: 10px 10px 0 0;

    .btn__show__payment{
      padding: 5px;
      text-transform: capitalize;
      font-weight: 500;
    }

  }

  &__footer{
    width: 100%;
    padding: 15px;
    border-top: 1px solid #eaeaea;
    margin-top: 20px;
      &__steps{
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 575px) {
          flex-direction: column;
          text-align: center;
        }
        @media only screen
        and (min-device-width : 768px)
        and (max-device-width : 1024px){
          flex-direction: column;
          text-align: center;
        }

        .step{
            padding: 8px 16px;
            border-radius: 16px;
            font-size: 16px;
            @media only screen and (max-width: 575px) {
              margin-top: 15px;
            }

          @media only screen
          and (min-device-width : 768px)
          and (max-device-width : 1024px){
            margin-top: 15px;

          }
          }
      }
  }

  &__content{
    @media only screen and (max-width: 575px) {
      flex-direction: column;
    }
  }


}
