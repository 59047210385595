.modal-calculator {
  &--title {
    color: #72c7b6;
  }
  &--img {
    width: 350px;
    height: 350px;
    min-width: 350px;
    min-height: 350px;
  }
  &--label {
    color: rgba(45, 45, 45, 0.5);
    text-align: center;
    font-size: 1.5rem;
  }
  &--product {
    color: #2d2d2d;
  }
  &--input {
    border-radius: 10px;
    text-align: center;
    float: left;
  }

  &--input-group {
    width: 50%;
    text-align: right;
    margin-left: auto;
    margin-right: auto;
    font-size: 1rem;
  }

  &--unity-input {
    border: 1px solid #ced4da;
    border-radius: 10px;
    text-align: right;
    float: left;
    border-right: 0!important;
    padding-right: 0.4rem;
    padding-left: 0.4rem;
    font-size: 1rem;
    height: calc(1.5em + .75rem + 2px);
  }

  &--unity-box {
    border-radius: 10px;
    border: 1px solid #ced4da;
    background:white!important;
    border-left:none!important;
    color:rgba(45, 45, 45, 0.5);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 1rem;
    padding-top: 0.62rem;
    padding-bottom: 0.62rem;
    padding-right: 0.375rem;
    padding-left: 0.375rem;
    font-weight: 100;
    height: calc(1.5em + .75rem + 2px);
  }

  &--unity-box-disabled {
    background:#e9ecef!important;
  }

  &--span {
    position: absolute;
    display: block;
    text-align: end;
    right: 0;
  }
}
