.admin_patient_detail{
  padding:2.2rem;
  @media only screen and (max-width: 767px) {
    padding:0;
  }
  h5{
    font-size: 16px;
    color: $colorThird;
    padding-bottom: .625rem;
    text-transform: uppercase;
    font-weight: 600;
  }
  p{
    font-size: 16px;
    line-height: 1.25rem;
    color: $colorThird;
    span{
      display: block;
      padding-bottom: 10px;
    }
  }

  &_title{
    text-transform: capitalize;
    font-size: 30px;
    color: $colorThird;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
  }
  &_btn_edit{
    width: 200px;
  }
    .profile__card__description__link{
      display: none;
    }
}
