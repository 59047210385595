.admin_physician_edit{
  padding: 2.2rem;
  @media only screen and (max-width: 575px) {
    padding: 0;
  }

  &_title{
    font-size: 30px;
    color: #414141;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
  }
  &_info_title{
    font-size: 20px;
    padding-bottom: 20px;
    font-weight: 700;
    color: #414141;
  }
}
