.button, .btn__primary {
  &-search-button {
    background: #1c2960;
  }
  &-font-size-20 {
    font-size: 20px !important;
  }
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 5px;
  cursor: pointer;

  &.primary {
    color: #ffffff;
    background-color: $colorPrimary;
    border: 1px solid $colorPrimary;
    transition: all 0.2s;

    &:hover {
      color: #ffffff;
      background-color: #43a995;
      transition: all 0.2s;
    }

    &:focus,
    &:active {
      color: #ffffff;
      background-color: #43a995 !important;
      border: 1px solid $colorPrimary !important;
      transition: all 0.2s;
      box-shadow: none !important;
    }

    &:disabled {
      background-color: #a2c1bb;
      border: 1px solid #a2c1bb;
      cursor: auto;
    }
  }

  &.white {
    color: $colorPrimary;
    background-color: #ffffff;
    border: 1px solid $colorPrimary;
    transition: all 0.2s;

    &:hover {
      color: $colorPrimary;
      background-color: #f5f5f5;
      transition: all 0.2s;
    }
  }

  &.blue {
    color: #ffffff;
    background-color: #1c2960;
    transition: all 0.2s;

    &:hover {
      color: #ffffff;
      background-color: #0e1430;
      transition: all 0.2s;
    }
  }

  &.link {
    color: #000000;
    font-size: 12px;
    line-height: 134.77%;
    background: transparent;
    border: none;

    &:hover {
      text-decoration: underline;
      transition: all 0.2s;
    }
  }

  &.outline {
    &-dark {
      color: #000;
      background-color: #ffffff;
      border: 1px solid #000;
      transition: all 0.2s;

      &:hover {
        color: #000;
        background-color: #f5f5f5;
        transition: all 0.2s;
      }
    }

    &-blue {
      color: #1c2960;
      background-color: #ffffff;
      border: 1px solid #1c2960;
      transition: all 0.2s;

      &:hover {
        color: #1c2960;
        background-color: #f5f5f5;
        transition: all 0.2s;
      }
    }

    &-primary {
      color: $colorPrimary;
      background-color: #ffffff;
      border: 1px solid $colorPrimary;
      transition: all 0.2s;

      &:hover {
        color: $colorPrimary;
        background-color: #f5f5f5;
        transition: all 0.2s;
      }
    }

    &-danger {
      color: #e60a0a;
      background-color: #ffffff;
      border: 1px solid #e60a0a;
      transition: all 0.2s;

      &:hover {
        color: #e60a0a;
        background-color: #f5f5f5;
        transition: all 0.2s;
      }
    }
  }

  &.danger {
    color: #ffffff;
    background-color: #e60a0a;
    border: 1px solid #e60a0a;
    transition: all 0.2s;

    &:hover {
      color: #f5f5f5;
      background-color: #b80909;
      transition: all 0.2s;
    }
  }

  &.sm {
    width: 8.8rem;
    font-size: 1.1rem;
  }

  &.md {
    width: 12.5rem;
    font-size: 1.1rem;
    //padding-top: 0.8rem;
    //padding-bottom: 0.8rem;
    @media only screen and (max-width: 575px) {
      font-size: 1rem;
      width: 10.8rem;
    }
  }

  &.md-lg {
    width: 19rem;
    font-size: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    @media only screen and (max-width: 575px) {
      width: 12rem;
      font-size: 0.9rem;
    }
  }

  &.lg {
    width: 21rem;
    font-size: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @media only screen and (max-width: 575px) {
      width: 12rem;
      font-size: 0.9rem;
    }
  }

  &.xl {
    width: 30.5rem;
    font-size: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &.radius {
    &.left {
      border-radius: 5px 0 0 5px;
    }

    &.right {
      border-radius: 0 5px 5px 0;
    }

    &.none {
      border-radius: unset;
    }
  }
}
