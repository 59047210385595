.patient__profile__edit{
  padding: 2.2rem;
  @media only screen and (max-width: 575px) {
    padding: 15px 0;
  }
  &__title{
    font-size: 30px;
    color: $colorThird;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
    @media only screen and (max-width: 575px) {
      font-size: 28px;
    }
  }
  &__info__title{
    font-size: 20px;
    padding-bottom: 20px;
    font-weight: 700;
    color: $colorThird;
    @media only screen and (max-width: 575px) {
       font-size: 20px;
    }
  }

  &__skeleton__mobile__botton{
    @media only screen and (max-width: 575px) {
      margin-bottom: 20px;
    }
  }

  &__skeleton__mobile__button{
    @media only screen and (max-width: 575px) {
      width: 100% !important;
    }
  }

  .gender__select{
    width: 47%;
    @media only screen and (max-width: 575px) {
      width: 100% ;
    }
  }

}
