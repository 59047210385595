.thumbs{
  border: 1px solid #efefef;
  width: 100px;
  height: 100px;
  padding: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    display: flex;
    min-width: 0;
    img {
      display: block;
      width: 90px;
      height: 90px;
    }
    svg {
      fill: $colorThird;
      height: 35px;
      width: 35px;
    }
  }

  &.lg {
    width: 200px;
    height: 200px;
  }
}
