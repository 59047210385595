.patient__what__Now{
  padding: 2.2rem;

  &__title{
    color: $colorThird;
    font-size: 32px;
    font-weight: 700;
    @media only screen and (max-width: 575px) {
      font-size: 25px;
    }
  }

  &__box{
    min-height: 200px;
    padding: 15px;
    background: #fff;
    border-radius: 8px 8px 0 0;
    box-shadow: -1px 0px 13px 0px rgba(214,214,214,0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;


    &__icon__circle{
      border: 2px solid #efefef;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      i{
        font-size: 35px;
        color: $colorThird;
      }
    }

    &:hover{
      border-bottom: 3px solid  $colorPrimary;

      i{
        font-size: 35px;
        color: $colorPrimary;
      }

    }

    &__title{
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      color: $colorThird;
      margin-bottom: 10px;
    }

    &__description{
      p{
        font-size: 16px;
      }
    }

    &__blog{
      cursor: pointer;
    }
  }

  &__requirements{
    border: 1px solid #efefef;
    padding: 20px;
    margin-bottom: 35px;
    border-radius: 16px;

    &__title{
      font-size: 18px;
      color: $colorThird;
      font-weight: 700;
      @media only screen and (max-width: 575px) {
        font-size: 16px;
      }
    }

    ol{
      padding-left: 20px;
      li{
        margin-top: 10px;
        font-size: 16px;
      }
    }
  }
}
