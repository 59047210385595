.admin_prescription_item{
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: none;
  box-shadow: 0 2px 5px 2px rgba(222, 222, 222, 0.4);
  margin-bottom: 50px;
  &_header{
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    &_title{
      font-size: 18px;
      color: $colorThird;
    }
  }
  &_footer{
    padding: 20px 0;
    margin-top: 30px;
    &_steps{
      padding-top: 10px;
    }
    &_progress{
      background-color: #e9ecef;
    }
  }
  img{
    width: 30px;
    height: 30px;
  }
  h6{
    font-size: 16px;
    padding-bottom: 15px;
    font-weight: 700;
    text-transform: uppercase;
  }
  &_file{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
  }
}
