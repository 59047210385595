.admin_brand{
  padding: 2.2rem;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
  &_title{
    font-size: 30px;
    color: $colorThird;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
  }

  &_product{
    height: auto;
    width: 100%;
    box-shadow: rgba(214, 214, 214, 0.5) 0 2px 4px;
    border-radius: 10px 10px 0 0;
    border-bottom: 4px solid rgb(255, 255, 255);
    img {
      width: 100%;
      height: 300px;
      box-shadow: rgb(226, 226, 226) 0 1px 3px 1px;
      margin-bottom: 20px;
      object-fit: contain;
    }
    &_name{
      font-size:1.125rem;
      color: $colorThird;
      height: 50px;
    }
    &_price{
      padding: 10px 0;
      font-size: 20px;
    }
  }

}
