.physician__home{
  padding: 2.2rem;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
  &__title{
    text-transform: capitalize;
    font-size: 30px;
    color: $colorThird;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
    margin-bottom: 20px;
    @media only screen and (max-width: 768px) {
      text-align: center;
      margin-top: 20px;
    }
  }

}
