.patient-list__physician {
  padding: 2rem;
  @media only screen and (max-width: 575px) {
    padding: 15px;
  }

  .icon {
    width: 20px;
    height: 20px;

    &.telemed {
      fill: $colorPrimary;
    }
  }

  &-search__btn {
    margin-top: 2.6rem;
  }
  .attention-text{
    background-color: $colorTelemed;
    display: inline-block;
    padding: 2px 8px;
    margin: 0 5px;
    border-radius: 16px;
    color: #fff;
    font-weight: normal;
  }

  &__skeleton{

    &__header{
      text-align: center;
      margin-bottom: 15px;

    }
    &__telemed{
      @media only screen and (max-width: 575px) {
        width: 100% !important;
      }
    }
    &__about{
      @media only screen and (max-width: 575px) {
        padding: 20px;
      }
    }

  }
}

.custom-spinner-border {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}
