.modal-confirm-vote {
  &_modal {
    border-radius: 30px 30px 30px 30px !important;
    background-color: #FFFFFF;
  }

  &_button {
    font-weight: bold;
    color: white;
    background-color: #43a995;
    font-size: 1.25rem;
  }

  &_icon {
    color: #00FF7F;
  }

  &_titulo {
    font-weight: 700;
    font-style: normal;
    color: #2E2E2EFF;
    text-decoration: none;
    text-transform: uppercase;
  }

  &_text {
    font-weight: 400;
    font-style: normal;
    color: #2E2E2EFF;
    text-decoration: none;
  }

  &_link {
    cursor: pointer;
  }

}
