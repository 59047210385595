.physician__patients__patient__card{
  box-shadow: 0 2px 5px 2px rgba(222, 222, 222, 0.4);
  padding: 0.938rem;
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  align-items: center;
  border-bottom: 3px solid #fff;
    &:hover{
      border-bottom: 3px solid #72c7b6;
    }
  &__avatar{
    img{
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
    }
  }
  &__description{
    color: $colorThird;
    padding-left: 10px;
  }
}
