.admin_physician_card{
  box-shadow: 0 1px 3px 2px rgba(214,214,214,0.5);
  padding-left: 0.938rem;
  border-radius: 1rem;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
  &_avatar{
    @media only screen and (max-width: 768px) {
      display: flex;
      justify-content: center;
    }
    &_img{
      height: 5rem;
      width: 5rem;
    }
  }

  &_body{
    padding: 1.25rem;
    &_header{
      display: flex;
      align-items: center;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
    }
    &_title{
      font-size: 1rem;
      font-weight: 700;
      color: #414141;
      margin-bottom: 0;
      margin-top: 5px;
      @media only screen and (max-width: 768px) {
        text-align: center;
        padding-bottom: 10px;
      }
    }
    h4>span{
      font-size: 16px;
      padding: .188rem .938rem;
      border-radius: 16px;
      margin: .313rem 0 .313rem .625rem;
      background-color: #72c7b6;
      color: #fff;

    }

    h4{
      @media only screen and (max-width: 768px) {
        margin-bottom: 15px;
      }
    }

    .telemed_badge{
      background-color: #db8ef8!important;
      color: #fff;

      i{
        color: #fff;
        padding-right: .625rem;
        font-size: 14px;
      }

    }
    p{
      color: #414141;
      font-weight: 500;
      font-size: 1rem;
      margin-top: 10px;
    }


  }
  &_icon_vector{
    z-index: 5;
  }

  &_container{
    &_approve{
      &.physician_card-success{
        overflow: hidden;
        background: #fcfcfc;
        border-radius: 16px;
        width: 100%;
        cursor: pointer;
        height: auto;
        position: relative;
        &:after {
          @media only screen and (min-width: 575px) {
            content: '';
            position: absolute;
            z-index: 2;
            top: -25px;
            right: -24px;
            width: 3.75rem;
            height: 2.813rem;
            transform: rotate(40deg);
            background-color: #72c7b6;
          }
        }
      }

    }
    &_pending {
      &.physician_card-pending {
        overflow: hidden;
        height: auto;
        background: #fcfcfc;
        border-radius: 16px;
        width: 100%;
        cursor: pointer;
        position: relative;
      }
      &:after {
        @media only screen and (min-width: 575px) {
          content: '';
          position: absolute;
          z-index: 2;
          top: -25px;
          right: -24px;
          width: 3.75rem;
          height: 2.813rem;
          transform: rotate(40deg);
          background-color: #ff7f50;
        }
      }
    }

    &_disapprove {
      &.physician_card-disapprove {
        overflow: hidden;
        height: auto;
        background: #fcfcfc;
        border-radius: 16px;
        width: 100%;
        cursor: pointer;
        position: relative;
      }
      &:after {
        @media only screen and (min-width: 575px) {
          content: '';
          position: absolute;
          z-index: 2;
          top: -25px;
          right: -24px;
          width: 3.75rem;
          height: 2.813rem;
          transform: rotate(40deg);
          background-color: #eb4d4b;
        }
      }
    }
  }



}
