.appointment {
  &-list {
    &-icon {
      -ms-flex-pack: center !important;
      justify-content: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
      display: flex;
      width: 2rem;
      height: 2rem;
    }
  }
}
