.products{
  color:$colorThird;
  &__title{
    font-size: 2rem;
  }
  p{
    font-size: 1.1rem;
    line-height: 1.7rem;
  }
  &__btn__primary{
    text-transform: none;
    font-weight: normal;
  }
}
