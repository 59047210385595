.admin_physician_edit_physician{
  padding: 2.2rem;

  &_title{
    font-size: 30px;
    color: #414141;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
  }

  &_info_title{
    font-size: 20px;
    padding-bottom: 20px;
    font-weight: 700;
    color: #414141;
  }

}
