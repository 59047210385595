.physician-card {
  box-shadow: 0 2px 3px rgba(214,214,214,0.5);
  padding-left: 0.938rem;
  border-radius: 1rem;
    @media only screen and (max-width: 768px) {
        padding: 0;
    }
  &-ticket {
    width: 13.25rem;
    height: 2.125rem;
    border-radius: 4px;
    background: #72c7b6;
    border: #72c7b6;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.313rem;
    color: #000000;
  }

  &-margin-ticket {
    margin-left: 5rem;
  }

  &-vector {
    width: 0;
    height: 0;
    border-bottom: 50px solid transparent;
    border-left: 50px solid #4c4b63;
    border-radius: 0 30% 100% 0;

    transform: skew(45deg) translateX(27px);
  }

  &-icon-vector {
    z-index: 5;
  }

  &-border-check {
    border-left-color: #72c7b6;
  }

  &-border-times {
    border-left-color: #cacaca;
  }

  &-border-question {
    border-left-color: #f0d271;
  }

  &-container-approve {
    border: 1px solid;
    border-color: #e9e9e9;
  }

  &-container-disapprove {
    border: 1px solid;
    border-color: #e9e9e9;
  }

  &-container-pending {
    border: 1px solid;
    border-color: #e9e9e9;
  }

  &__card-header{
    @media only screen and (max-width: 807px) {
      display: flex;
      flex-direction: column;
    }


  }

  .desc-margin {
    margin-left: 3.75rem !important;
  }

  .badge{
    white-space: normal;
  }
  h5 {
    margin-top: 1.063rem;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.75rem;

    color: #000000;
  }

  i {
    font-size: 18px;
    color: #000000;
  }

  &-avatar {
    height: 5rem;
    width: 5rem;
    @media only screen and (max-width: 575px) {
      display: flex;
      margin-top: 0.938rem;
    }
  }

  .checked {
    height: 1.875rem;
  }

  .text-overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 17.188rem;
  }
  .card-body{
    .card-title{
      font-size: 16px;
      font-weight: 700;
      color: $colorThird;
      margin-bottom: 0;
    }
    p{
      color: $colorThird;
      font-weight: 500;
      font-size: 16px;
      margin-top: 10px;
    }
    h4{
      font-size: unset;
    }
    h4>span{
      font-size: 16px;
      padding: 0.188rem 0.938rem;
      border-radius: 16px;
      margin: 0.313rem 0 0.313rem 0.625rem;
      background-color: $colorPrimary;
    }
    .telemed-badge{
      background-color: $colorTelemed;
      color: #fff;
      display: flex;
      align-items: center;
        i{
          color: #fff;
          padding-right: 0.625rem;
          font-size: 14px;
        }
    }
  }

  &__title{
    color:$colorThird;
    font-size: 16px;
    font-weight: 500;
    margin-top: 0.5rem;

  }
  &__card-span{
    font-weight: 300;
    line-height: 25px;
  }
  &__about{
    color:$colorThird;
    line-height: 1.158rem;
    height:3.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    @media only screen and (max-width: 575px) {
      height:3.4rem;
    }
  }

}

.physician-card-success {
  overflow: hidden;
  display: flex;
  background: #fcfcfc;
  border-radius: 16px;
  width: 100%;
  cursor: pointer;
  height: auto;

}

.physician-card-pending {
  overflow: hidden;
  display: flex;
  height: auto;
  background: #fcfcfc;
  border-radius: 16px;
  width: 100%;
  cursor: pointer;
}

.physician-card-disapprove {
  overflow: hidden;
  display: flex;
  height: auto;
  background: #fcfcfc;
  border-radius: 16px;
  width: 100%;
  cursor: pointer;
}

.physician-card-success:after {
  @media only screen and (min-width: 575px) {
    content: '';
    position: absolute;
    z-index: 2;
    top: -1.25rem;
    right: -1.313rem;
    width: 3.75rem;
    height: 2.813rem;
    transform: rotate(40deg);
    background-color: #72c7b6;
  }
}

.physician-card-pending:after {
  @media only screen and (min-width: 575px) {
    content: '';
    position: absolute;
    z-index: 2;
    top: -1.25rem;
    right: -1.313rem;
    width: 3.75rem;
    height: 2.813rem;
    transform: rotate(40deg);
    background-color: #f0d271;
  }
}

.physician-card-disapprove:after {
  @media only screen and (min-width: 575px) {
    content: '';
    position: absolute;
    z-index: 2;
    top: -1.25rem;
    right: -1.313rem;
    width: 3.75rem;
    height: 2.813rem;
    transform: rotate(40deg);
    background-color: #cacaca;
  }
}


