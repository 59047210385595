.button__secondary{
  color: $colorThird;
  background-color: #fff;
  border:1px solid $colorPrimary;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  &:hover{
    opacity: 0.7;
  }
}
