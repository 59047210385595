.physician__prescriptions__prescription__sendPrescription{
  .btn__primary__prescription{
    text-transform: none;
    padding: .375rem .75rem;
    font-size: 16px;
    &.disabled{
      cursor: not-allowed;
    }
  }
}
