.admin_orders_list{
  padding: 2.2rem;
  @media only screen and (max-width: 768px) {
    padding: 10px;
  }
  &_card{
    box-shadow: 0 1px 3px 2px rgba(214, 214, 214, 0.5);
    padding: .938rem;
    border-radius: 8px;
    &_img{
      width: 200px;
      height: 200px;
    }
  }
  &_title{
    font-size: 30px;
    color: #414141;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
  }
}
