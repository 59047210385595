.footer{
  position: relative;

  //height: 40rem;
  box-shadow: 0 -3px 5px rgba(214,214,214,0.5);
  z-index: 2;
  background-color: $colorWhite;
  @media only screen and (max-width: 575px) {
    padding-top: 0.5rem;
  }

  .social-icon {
    @media only screen and (max-width: 575px) {
      width: 1.3rem !important;
      height: 1.3rem !important;
    }
  }

  .footer-ribbon {
    background: rgb(114, 199, 182);
    border-radius: 99px 99px 0 0;
    position: absolute;
    bottom: 0;
    width: 99.7%;
    line-height: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .footer-row {
    overflow: hidden;
    position: relative;
    height: 100%;
    padding-top: 1rem;
    padding-bottom: 2rem;
    @media only screen and (max-width: 575px) {
      padding-bottom: 4rem;
    }

  }

  .right-leaf {
    position: absolute;
    top: 5px;
    right: -80px;
    height: 13rem;
    transition: all 0.5s ease-in-out;
    opacity: 0.2;
    @media only screen and (max-width: 575px) {
      height: 10rem;
      top: 0;
      right: -4rem;
    }
  }

  .right-leaf:hover {
    opacity: 0.75;
  }



  .left-leaf {
    transition: all 0.5s ease-in-out;
    opacity: 0.2;
    position: absolute;
    bottom: 0.5rem;
    left: -4.5rem;
    height: 13rem;
    @media only screen and (max-width: 575px) {
      height: 10rem;
      bottom: 1.5rem;
      left: -3rem;
    }

  }

  .left-leaf:hover {
    opacity: 0.75;
  }

  .logo-vert {
    width: 8rem;
    margin-left: 1.3rem;
  }


  .logo {
    width: 180px;
    height: 52px;
  }

  .text {
    font-size: 0.9rem;
  }

  .anchor {
    transition: all 0.2s;

    &:hover {
      color: $colorPrimary;
      transition: all 0.2s;
    }
  }


  .social {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 2.5rem;
    background-color: $colorPrimary;
    transition: all 0.2s;

    @media only screen and (min-width: 1440px) {
      width: 3rem;
      height: 3rem;
    }
    @media only screen and (max-width: 575px) {
      width: 2.5rem;
      height: 2.5rem;
    }

    &:hover {
      transform: scale(1.1);
      transition: all 0.2s;
    }

    &:not(:last-of-type) {
      margin-right: 1rem;
    }

    &-icon {
      width: 1.5rem;
      height: 1.5rem;
      fill: $colorWhite;
    }
  }
}
