.faq{
  color: $colorThird;
  &__title{
    font-size: 2rem;
  }
  &__sub__title{
    font-size: 1.5rem;
    text-align: center;
    color: #6F6F6F;
  }
  .button__primary{
    width: 18rem;
    font-size: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-transform: none;
    border-radius: 8px 0 0 8px;
    font-weight: 500;
    outline: none;
    box-shadow: none;
      &.outline-primary{
        color: $colorPrimary!important;
        background-color: #ffffff;
        border: 1px solid $colorPrimary;
        transition: all 0.2s;
        outline: none;
        box-shadow: none;
      }
    &.for__physician{
      border-radius: 0 8px 8px 0;
    }
  }

  &__accordion{
    &__title{
      font-size: 1.3rem;
      cursor: pointer;
      text-align: left;
      padding-right: 15px;
      color: $colorThird;
    }
    &__answer{
      font-size: 1.1rem;
      line-height: 1.5rem;
      @media only screen and (max-width: 768px) {
        line-height: 1.5rem;
        text-align: justify;

      }
    }

    &__for__text{
      font-size: 1.5rem;
    }
    &__question{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border: none;
      background-color: #fff;
      font-size: 30px;
      padding: 2rem 15px;
      border-top: solid 1px #ccc;

      svg {
        width: 20px;
        height: 20px;
        min-height: 20px;
        min-width: 20px;
        fill: $colorThird;
      }
    }
  }

}
