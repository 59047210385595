.admin_medicines_new_medicine{
  padding: 2.2rem;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
  &_title{
    font-size: 30px;
    color: #414141;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
  }
}
