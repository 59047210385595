.admin_physician_list {
  padding: 2.2rem;
  @media only screen and (max-width: 767px) {
    padding:0;
  }
  .btn_search_button {
    text-transform: none;
    width: 120px;
    margin-top: 10px;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
  &_title{
    font-size: 30px;
    color: $colorThird;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
    @media only screen and (max-width: 767px) {
      font-size: 28px;
    }
  }

  .badge {
    background-color: tomato;
    border-radius: 16px;
    padding: 5px 16px;
    margin-right: 10px;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    font-size: 1rem;

    &:hover {
      opacity: 0.7;
    }

    &_approved {
      background-color: $colorPrimary;
    }

    &_pending {
      background-color: #ff7f50;
    }

    &_disapproved {
      background-color: #eb4d4b;
    }
    &_all{
      background-color: #c3c3c3;
    }
  }
  &_skeleton{
    &_avatar{
      @media only screen and (max-width: 767px) {
        display: flex;
        justify-content: center;
      }
    }
    &_width{
      @media only screen and (max-width: 767px) {
          width: 100% !important;
      }
    }
    &_padding{
      padding: 40px;
      @media only screen and (max-width: 767px) {
       padding-top: 10px;
      }
    }
  }

}
