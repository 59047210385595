.custom__select{
  label{
    @media only screen and (max-width: 575px) {
      font-size: 16px;
    }
    select{
      margin: 10px 0;
      @media only screen and (max-width: 575px) {
        font-size: 16px;
      }
    }
  }
}
