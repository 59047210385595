.calendar {
  width: 100%;

  &.calendar-set-schedule {
    padding: 1.563rem 3.125rem;
    border-radius: 1rem 1rem 0 0;

  }

  .fc-timegrid-col.fc-day-today {
    background-color: #fff;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0.625rem;
  }


  .fc-timegrid-slots {
    background: #F5F6FA;


  }

  .fc-view-harness {
    border: 3px solid #72C7B6;
  }

  .fc-col-header .fc-day-sat, .fc-col-header .fc-day-sun {
    background: #E2E5EB;
  }

  .fc-col-header th {
    border-style: none;
    border-bottom: 1px solid #ddd;
  }

  .fc-scrollgrid-sync-inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fc-col-header-cell .fc-scrollgrid-sync-inner a {
    color: #858997;
    text-transform: capitalize;
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;
    width: 1.875rem;
    display: flex;
  }

  .fc-toolbar-chunk > div {
    display: flex;
    align-items: center;
  }


  .fc-toolbar-chunk .fc-next-button, .fc-toolbar-chunk .fc-prev-button {
    background: transparent;
    border-radius: 1.25rem;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.313rem;
    border: none;
    position: relative;

  }

  .fc-toolbar-chunk .fc-icon-chevron-left, .fc-toolbar-chunk .fc-icon-chevron-right {
    display: none;
  }

  .fc-toolbar-chunk .fc-prev-button::after {
    content: url("../../images/telemed/calendar-date-left.png");
    width: 1.875rem;
    height: 1.875rem;
    position: absolute;
    top: 0;
    left: -0.188rem;
  }

  .fc-toolbar-chunk .fc-next-button::after {
    content: url("../../images/telemed/calendar-date-right.png");
    width: 1.875rem;
    height: 1.875rem;
    position: absolute;
    top: 0;
    left: -0.188rem;
  }

  .fc-toolbar-chunk .fc-toolbar-title {
    color: #414141;
    font-size: 1.5rem;
    font-weight: normal;
  }

  .fc .fc-button-primary:focus {
    box-shadow: none;
  }

  .fc .fc-button-primary:not(:disabled):active:focus, .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
  }

  .fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active {
    color: #fff;
    background-color: #fff;
    border-color: transparent;
  }

  .fc-toolbar-chunk .fc-today-button {
    padding: 0.5rem 1rem;
    width: auto;
    height: 2rem;
    border-radius: 1.25rem;
    border: 1px solid #E2E5EB;
    background-color: #ffffff;
    font-size: 0;

    &::before {
      content: 'HOJE';
      font-size: 0.75rem;
      color: #1C2960;
    }
  }

  .fc .fc-timegrid-slot-label {
    background: #ffffff;
  }

  //classes for doctor view
  .selected-both {
    background-color: #ffffff;
    border: 1px solid #72C7B6;

    &::before {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      content: url("../../images/telemed/check-double.png");
    }

    .fc-event-main {
      display: none;
    }
  }

  .selected-block {
    background-color: #E2E5EB;
    border: none;

    &::before {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      content: url("../../images/telemed/block.png");
    }

    .fc-event-main {
      display: none;
    }
  }

  .selected-presential {
    background-color: #ffffff;
    border: 1px solid #68C8FF;

    &::before {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      content: url("/../../images/telemed/presencial.png");
    }

    .fc-event-main {
      display: none;
    }
  }

  .selected-video {
    background-color: #ffffff;
    border: 1px solid #DB8EF8;

    &::before {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      content: url("../../images/telemed/video.png");
    }

    .fc-event-main {
      display: none;
    }
  }

  //classes for agenda view
  .view-telemed, .view-presential {
    background-color: #ffffff;
    border: 1px solid #0fd447;
    padding: 0.313rem 0 0 0.313rem;

    &::before {
      position: absolute;
      right: 5px;
      top: 3px;
    }

    .fc-event-main {
      color: #414141;

      .fc-event-main-frame {
        .fc-event-time {
          display: none;
        }

        .fc-event-title.fc-sticky {
          margin-top: 0.938rem;
        }
      }
    }

    &.payment-ok {
      &::after {
        content: 'Minha consulta';
        position: absolute;
        left: 5px;
        top: 3px;
        color: #ffff;
        width: auto;
        height: auto;
        padding: 0 8px;
        line-height: 1.063rem;
        border-radius: 0.938rem;
        font-weight: 500;
        background-color: #0fd447;
      }
    }

    &.waiting-confirmation {
      border: 1px solid #F58C3D;

      &::after {
        content: 'Pendente';
        position: absolute;
        left: 5px;
        top: 3px;
        color: #ffff;
        width: auto;
        height: auto;
        padding: 0 8px;
        line-height: 1.063rem;
        border-radius: 0.938rem;
        font-weight: 500;
        background-color: #F58C3D;
      }

    }

    &.waiting-payment {
      border: 1px solid #bdbdbd;
      &::after {
        content: 'Pag. Pendente';
        position: absolute;
        left: 5px;
        top: 3px;
        color: #414141;
        width: auto;
        height: auto;
        padding: 0 8px;
        line-height: 1.063rem;
        border-radius: 0.938rem;
        font-weight: 500;
        background-color: #EDF1F7;
      }
    }

    &.shift-blocked {
      border: 1px solid #e8e8e8;
      background-color: #e8e8e8;

      &::after {
        content: 'Indisponível';
        position: absolute;
        left: 5px;
        top: 3px;
        color: #414141;
        width: auto;
        height: auto;
        padding: 0 8px;
        line-height: 1.063rem;
        border-radius: 0.938rem;
        font-weight: 500;
        background-color: #EDF1F7;
      }

      &::before {
        content: url("../../images/telemed/mini-block.png");
      }
    }

    &.awaiting-appointment {
      border: 1px solid #68C8FF;
      background-color: #68C8FF;

      &::after {
        content: 'Disponivel';
        position: absolute;
        left: 5px;
        top: 3px;
        color: #68C8FF;
        width: auto;
        height: auto;
        padding: 0 8px;
        line-height: 1.063rem;
        border-radius: 0.938rem;
        font-weight: 500;
        background-color: #ffffff;
      }
    }

    &.shift-unavailable {
      border: 1px solid #F9E4A6;
      background-color: #F9E4A6;

      &::after {
        content: 'Indisponível';
        position: absolute;
        left: 5px;
        top: 3px;
        color: #ffffff;
        width: auto;
        height: auto;
        padding: 0 8px;
        line-height: 1.063rem;
        border-radius: 0.938rem;
        font-weight: 500;
        background-color: #f2c94c;
      }

      &::before {
        content: url("../../images/telemed/mini-exclamation.png");
      }
    }

  }

  .fc-timegrid-event-harness {
    .view-presential {
      &::before {
        content: url("../../images/telemed/mini-presencial.png");
      }
    }
  }


  //custom buttons
  .fc-btn1-button.fc-button {
    background-color: #72C7B6;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    padding: 0.5rem 1rem;
    margin: 0 0.625rem;
    border-radius: 0.375rem;

    &:hover {
      opacity: 0.7;
    }
  }

  .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .fc-btn2-button.fc-button {
    border: none;
    background-color: #ffffff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    color: #414141;

    &:hover {
      opacity: 0.7;
    }
  }
}
