.physician__prescriptions__prescription__AskPatient{
  .btn__primary__prescription{
    text-transform: none;
    padding: .375rem .75rem;
    font-size: 16px;
  }
  &__patient{
    display: flex;
    align-items: center;
    justify-content: center;

    .thumb {
      margin: 1rem;
    }

    .name {
    }
  }

}
