.admin_medicines{
  padding: 2.2rem;
  @media only screen and (max-width: 575px) {
    padding: 0;
  }
  &_title{
    font-size: 30px;
    color: $colorThird;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
  }
  &_brands{
    box-shadow: 0 1px 3px 2px rgba(214, 214, 214, 0.5);
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
      height: 80px;
    }

    &_avatar {
      img {
        width: 100%;
        height: 100px;
        object-fit:contain;
        padding-left: 10px;
        @media only screen and (max-width: 768px) {
          width: 100px;
          height: 80px;
        }
      }
    }

    &_desc {
      &_name{
        font-size: 16px;
        padding: 10px 0;
        color: $colorThird;
        font-weight: bold;
      }

      &_badge_active{
        font-size: 16px;
        padding: .188rem .938rem;
        border-radius: 16px;
        background-color: $colorPrimary;
        color: #fff;
      }
      &_badge_inactive{
        font-size: 16px;
        padding: .188rem .938rem;
        border-radius: 16px;
        background-color: #eb4d4b;
        color: #fff;
      }
    }

  }

  .button__primary{
    text-transform: none;
    border: none;
    &.blue{
      background-color: #1c2960;
    }
  }

}
