.admin_patients_profile_card{
    box-shadow: 0 1px 3px 2px rgba(214, 214, 214,0.5);
    padding: .938rem;
    border-radius: 1rem;
    min-height: 120px;

  &_avatar{
    @media only screen and (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img{
      height: 5rem;
      width: 5rem;
      border-radius: 50%;
    }
  }
  &_desc{
    padding-left: 1rem;
    @media only screen and (max-width: 768px) {
        text-align: center;
    }

    h5{
      font-size: 16px;
      font-weight: 700;
      color: $colorThird;
      margin-top: 10px;
      margin-bottom: 0;
      @media only screen and (max-width: 1024px) {
        font-size: 14px;
        padding-left: 10px;
      }
    }

    p{
      color:  $colorThird;
      font-weight: 500;
      font-size: 16px;
      margin-top: 10px;
      @media only screen and (max-width: 1024px) {
          font-size: 12px;
          padding-left: 10px;
      }
    }
  }
}
