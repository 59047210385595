.width-rem-1 {
  width: 1rem !important;
}

.width-rem-2 {
  width: 2rem !important;
}

.width-rem-3 {
  width: 3rem !important;
}

.width-rem-4 {
  width: 4rem !important;
}

.width-rem-5 {
  width: 5rem !important;
}

.width-rem-6 {
  width: 6rem !important;
}

.width-rem-7 {
  width: 7rem !important;
}

.width-rem-8 {
  width: 8rem !important;
}

.width-rem-9 {
  width: 9rem !important;
}

.width-rem-10 {
  width: 10rem !important;
}

.height-rem-1 {
  height: 1rem !important;
}

.height-rem-2 {
  height: 2rem !important;
}

.height-rem-3 {
  height: 3rem !important;
}

.height-rem-4 {
  height: 4rem !important;
}

.height-rem-5 {
  height: 5rem !important;
}

.height-rem-6 {
  height: 6rem !important;
}

.height-rem-7 {
  height: 7rem !important;
}

.height-rem-8 {
  height: 8rem !important;
}

.height-rem-9 {
  height: 9rem !important;
}

.height-rem-10 {
  height: 10rem !important;
}
