.datepicker {
  &-date {
    @media only screen and (max-width: 769px) {
      display: none;
    }
  }

  &-input-date {
    @media only screen and (max-width: 769px) {
      width: 60%;
    }
  }

  &-button-now {
    @media only screen and (max-width: 769px) {
    }
  }

  &-buttons {
    @media only screen and (max-width: 769px) {
      width: 20%;
    }
  }
}
