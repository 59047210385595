.side--menu{
  padding-bottom: 7.8rem;
  height: 100%;
  background-color: $colorWhite;
  box-shadow: 1px 10px 12px 0 rgba(214,214,214,0.5);

  .item {
    cursor: pointer;
    color: #858997;
    &:hover {
      .anchor {
        color: $colorSecondary;
        fill: $colorSecondary;
        transition: all 0.2s;
      }
    }

    li{
      .nav-ribbon{
        margin-right: 0.625rem;
        background-color: transparent;
        border-left: 4px solid $colorWhite;
        padding: 0.625rem;
        width: 4.063rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      i{
        font-size: 20px;
      }
    }
    &.selected {
      .icon,
      .anchor {
        color: $colorSecondary;
        fill: $colorSecondary;

      }
    }

    &:not(:last-of-type) {
      margin-bottom:1.7rem;
    }


    &.active {
      color: #414141;
        .nav-ribbon{
          background-color: rgba($colorPrimary,0.15);
          border-left: 4px solid $colorPrimary;
          border-radius: 0 20px 20px 0;

        }
    }

  }
}
