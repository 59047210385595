.vote {
  &_card {
    border-radius: 30px 30px 30px 30px !important;

    &_header {
      border-bottom: 0;
      border-radius: 30px 30px 0 0 !important;
    }

    &_footer {
      border-top: 0;
      border-radius: 0 0 30px 30px !important;
    }
  }

  &_button {
    border-radius: 15px 15px 15px 15px !important;
  }

  &-info {
    text-align: center;
    font-size: 1.3rem;
    line-height: 1.5;
  }

  &-confirm {
    &-message {
      font-size: 25px;
    }

    &-title {
      font-size: 50px;
      text-transform: uppercase;
    }

    &-info {
      font-size: 40px;
      color: rgb(46, 46, 46);
    }
  }
}
