.physician__prescriptions__prescription__AskMedicine{
  .btn__primary__prescription{
    text-transform: none;
    padding: .375rem .75rem;
    font-size: 16px;
    &__remove{
      background-color:#e55039;
      color: #fff;
      border: none;
      text-transform: none;
      font-size: 16px;
      margin-top: 0!important;
      padding: .375rem .75rem;
    }
  }

  &__medicine__list{
    max-height: 270px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 5px;
    &__box{
      border-bottom: 1px solid #ced4da;
      padding: 10px 0;
    }
  }

  &__product__box{
    .thumbs{
      border-radius: 0;
        .inner{
          img{
            border-radius: 0;
          }
        }
    }

    .name{
      padding-left: 10px;
    }
  }

}
