@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}

.cursor-pointer {
  cursor: pointer;
}

.default-green {
  color: $defaultGreen;
}

.drc-background {
  background-color: $colorPrimary;

  &-inative {
    background-color: #cccccc;
  }
}

.error {
  color: $colorRed;
}

input.error, select.error {
  border-color: $colorRed;
}

.badge-video-service {
  background-color: #DB8EF8;
  padding: 0.188rem 0.938rem;
  border-radius: 1rem;
  color: #ffffff;
  font-size: 12px;

  i {
    padding-right: 0.313rem;
  }
}

@import "scale";
