.custom_input{
  position: relative;
  label{
    @media only screen and (max-width: 575px) {
      font-size: 16px;
    }
    input{
      margin: 10px 0;
      @media only screen and (max-width: 575px) {
        font-size: 16px;
      }
    }
  }
  &_icon{
    position: absolute;
    left: 0.625rem;
    top: 1.25rem;
    color: $colorThird;
    &_pad{
      padding-left: 2.188rem;
    }
    @media only screen and (max-width: 768px) {
      top:20px;
    }
  }
}
