.credit__card{
  display: flex!important;
  @media only screen and (max-width: 575px) {
      flex-direction: column;
  }

  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px){
    flex-direction: column;
  }

  .payment__buttons{
    display: flex;
    @media only screen and (max-width: 575px) {
      flex-direction: column;
    }

    @media only screen
    and (min-device-width : 768px)
    and (max-device-width : 1024px){
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }
  .payment__button{
    min-width: 160px;
    background-color: $colorPrimary;
    color: #fff;
    font-size: 16px;
    @media only screen and (max-width: 575px) {
      width: 100%;
    }
    &__clear{
      background-color: #fff;
      border: 1px solid $colorPrimary;
      margin: 0 15px;
      min-width: 160px;

      @media only screen and (max-width: 575px) {
        width: 100%;
        margin: 15px 0;
      }

    }
  }

  .rccs{
    padding-bottom: 20px;
  }
}
